import { $on, $off, $once, $emit } from "@/utils/gogocodeTransfer";
import * as Vue from "vue";

// 接口
import * as apis from "@/api/land";
import html2canvas from 'html2canvas';
export default {
  props: {
    content: Object,
    pageType: Boolean,
    tid: String,
    index: Number,
    hide_long_time: Number,
    source: String,
    landingPageId: Number,
    modelType: {
      type: String,
      default: "android"
    }
  },
  data() {
    return {
      img: "",
      baseimg: "",
      fontSize: "",
      screenWidth: document.body.clientWidth,
      cliw: "",
      clleft: "",
      cltop: "",
      imgsize: "",
      longTouch: false,
      showDialog: false,
      longTime: '',
      longTimeNum: 0,
      isLongPress: 1,
      imgUrl: ''
    };
  },
  created() {
    // 获取图片base64数据
    if (this.pageType) {
      // 小程序
      this.wechat_qrcode_trace();
    } else if (this.source) {
      // h5
      this.wechat_qrcode_trace();
      this.trace();
    } else {
      this.wechatqrcode();
    }
  },
  mounted() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 800) clientWidth = 800;
    let wsize = (clientWidth / 376).toFixed(2);
    this.imgsize = wsize * this.content.w;
    this.clleft = wsize * this.content.x;
    this.cltop = wsize * this.content.y;

    // 监听宽度变化，刷新页面
    window.onresize = () => {
      return (() => {
        location.reload();
      })();
    };
    if (!this.pageType && this.modelType === 'ios') {
      this.$nextTick(() => {
        setTimeout(() => {
          this.html2canvas2();
        }, 2000);
      });
    }
  },
  methods: {
    html2canvas2() {
      // console.log(document.getElementsByClassName("qrcodeAssembly")[0].innerHTML)
      var divContent = document.getElementById("qrcodeAssembly" + this.index);
      console.log(divContent);
      html2canvas(divContent, {
        useCORS: true,
        //这个为true代表允许跨域
        allowTaint: false,
        logging: false,
        letterRendering: true
      }).then(canvas => {
        const image = canvas.toDataURL('image/png');
        this.imgUrl = image;
      });
    },
    touchStart(e) {
      // 模拟长按
      clearTimeout(this.loop); // 再次清空定时器，防止重复注册定时器（会把点击事件也阻止掉）
      this.loop = setTimeout(() => {
        this.longPress();
      }, 1000);
    },
    touchend() {
      clearTimeout(this.loop); // 清空定时器，防止重复注册定时器
    },

    clear() {
      clearInterval(this.longTime);
    },
    //真正长按后应该执行的内容
    longPress() {
      console.log('长按了');
      // const parse = {
      //   tid: this.tid,
      //   step: "40",
      // };
      // apis.trace(parse).then((res) => {});
      clearInterval(this.longTime);
      this.longTimeNum = 0;
      this.isLongPress = 1;
      this.$emit('longEnd', {
        longTimeNum: this.longTimeNum,
        isLongPress: this.isLongPress
      });
      const parse = {
        tid: this.tid,
        step: "40"
      };
      apis.trace(parse).then(res => {});
      this.longTime = setInterval(() => {
        ++this.longTimeNum;
        this.isLongPress = 1;
        this.$emit('longEnd', {
          longTimeNum: this.longTimeNum,
          isLongPress: this.isLongPress
        });
        if (this.longTimeNum >= this.hide_long_time / 1000) {
          console.log(this.longTimeNum);
          clearInterval(this.longTime);
          this.isLongPress = 0;
          this.longTimeNum = 0;
          this.$emit('longEnd', {
            longTimeNum: this.longTimeNum,
            isLongPress: this.isLongPress
          });
        }
      }, 1000);
    },
    wechatqrcode() {
      const parse = {
        wechat_package_id: this.content.wechat
      };
      apis.wechatqrcode(parse).then(res => {
        if (res.code == "200") {
          this.img = res.data.qr_code;
          $emit(this, "userwechatid", res.data.id);
        } else {
          $emit(this, "userwechatid");
        }
        // ios环境下的H5模式才执行图片合并
        if (!this.pageType && this.modelType === "ios") {
          this.getImage(res.data.qr_code);
        }
      });
    },
    wechat_qrcode_trace() {
      let parse;
      parse = {
        tid: this.tid,
        wechat_package_id: this.content.wechat,
        landing_page_id: this.landingPageId
      };
      apis.wechat_qrcode_trace(parse).then(res => {
        this.img = res.data.qr_code;
        if (!this.pageType && this.modelType === "ios") {
          this.getImage(res.data.qr_code);
        }
      });
    },
    trace() {
      const parse = {
        tid: this.tid,
        step: "31"
      };
      apis.trace(parse).then(res => {});
    },
    // 图片流转换，canvas合并图片，解决ios长按识别图片层级提高问题
    getImage(img) {
      apis.getImage({
        image: img
      }).then(res => {
        this.baseimg = res.data.base64;
        // $emit(this, "handleCanvas");
      });
    }
  },

  emits: ["userwechatid", "handleCanvas"]
};