// 接口
import * as apis from '@/api/land';
export default {
  props: {
    currentRow: {
      type: Object,
      default: () => {}
    },
    uid: {
      type: [Number, String]
    },
    tid: {
      type: [Number, String]
    }
  },
  data() {
    return {
      show: false,
      icon: "<"
    };
  },
  mounted() {
    var pressTimer;
    const that = this;
    const txId = document.getElementById("tx");
    // 绑定长按事件
    document.getElementById("qrcodeContainer").addEventListener("touchstart", function (e) {
      pressTimer = setTimeout(function () {
        // 在这里添加二维码识别的逻辑
        that.changeMc(true);
        txId.style.bottom = '0';
      }, 300); // 长按时间设定为500ms
    });

    // 清除计时器，防止误触发
    document.getElementById("qrcodeContainer").addEventListener("touchend", function () {
      clearTimeout(pressTimer);
    });

    // 防止页面滚动时触发长按事件
    document.getElementById("qrcodeContainer").addEventListener("touchmove", function (e) {
      e.preventDefault();
    });
  },
  methods: {
    open() {
      this.show = true;
    },
    changeMc(status) {
      this.show = status;
      setTimeout(() => {
        const mcId = document.getElementById("mc");
        console.log(mcId, "mcId");
        if (status) {
          mcId.style.opacity = 0.6;
        } else {
          mcId.style.opacity = 0;
        }
      }, 10);
    },
    handleWx() {
      console.log(this.currentRow, "currentRow");
      const params = {
        uid: this.uid,
        tid: this.tid
      };
      if (typeof this.currentRow.wechat_package_id != 'undefined') {
        params.wechat_package_id = this.currentRow.wechat_package_id;
      }
      if (typeof this.currentRow.link_id != 'undefined') {
        params.link_id = this.currentRow.link_id;
      }
      apis.acquisitionScheme(params).then(res => {
        if (res.code == '200' && typeof res.data.ab_limit != 'undefined' && res.data.ab_limit == 1) {
          this.$emit('blackShow');
          return;
        }
        console.log(res, '获客助手接口');
        // window.location.href = res.data.acquisition_url
        window.location.href = decodeURIComponent(res.data.acquisition_url);
      });
    },
    onCancel() {
      const txId = document.getElementById("tx");
      txId.style.bottom = '-424px';
      this.show = false;
    },
    handleBack() {
      this.$emit("back");
    }
  }
};